

export default {
  name: 'JbInputPassword',
  inheritAttrs: false,
  props: {
    value: {
      type: undefined,
      required: true
    },
    showPassword: {
      type: Boolean,
      required: true
    }
  }
}
