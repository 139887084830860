import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=336f5a9a"
import script from "./LoginModal.vue?vue&type=script&lang=js"
export * from "./LoginModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JbLogin: require('/buddy/bayerova14a/nuxt/client/components/Jb/Login.vue').default})
