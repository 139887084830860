
export default {
  name: 'PageError',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head: () => ({
    title: 'Chyba',
    bodyAttrs: {
      id: 'error-page'
    }
  }),
  async created () {
    if (this.$auth.loggedIn) {
      await this.$laravel.setPermissions(this.$auth.user.permissions)
      await this.$laravel.setRoles(this.$auth.user.roles)
    } else {
      await this.$laravel.setPermissions([])
      await this.$laravel.setRoles([])
    }
  }
}
