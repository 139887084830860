
export default {
  name: 'JbLoginModal',
  created () {
    this.$axios.onError(async (error) => {
      if (error.response?.status === 401) {
        // await this.$auth.setUser(null)
        // console.log(error.response?.status, $auth)
        // await $auth.logout()
        // await this.$laravel.setPermissions([])
        // await this.$laravel.setRoles([])
        await this.$bvModal.show('modal-login')
      }

      throw error
    })
  }
}
