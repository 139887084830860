
export default {
  name: 'LayoutDefault',
  head: () => ({
    // title: 'Úvod',
  }),
  async created () {
    if (this.$auth.loggedIn) {
      await this.$laravel.setPermissions(this.$auth.user.permissions)
      await this.$laravel.setRoles(this.$auth.user.roles)
    } else {
      await this.$laravel.setPermissions([])
      await this.$laravel.setRoles([])
    }
  }
}
