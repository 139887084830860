
export default {
  name: 'JbLogin',
  props: {
    redirect: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showPassword: false,
    busy: false,
    model: {
      email: null,
      password: null
    }
  }),
  methods: {
    async onSubmit () {
      this.busy = true
      try {
        await this.$auth.loginWith('laravelSanctum', {
          data: {
            email: this.model.email,
            password: this.model.password,
            'g-recaptcha-response': await this.$recaptcha.execute('login')
          }
        })

        await this.$bvModal.hide('modal-login')

        if (this.redirect) {
          this.$router.push('/informace')
        } else {
          this.$root.$emit('refresh')
        }
      } catch (e) {
        this.busy = false
      }
    },
    getValidationState ({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? (valid ? null : false) : null
    },
    onHidden () {
      // Return focus to the button once hidden
      this.$refs.button.focus()
    }
  }
}
