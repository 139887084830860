
export default {
  name: 'JbNavItem',
  props: {
    navItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dropdownTimeout: null
  }),
  methods: {
    hoverHandler (isHovered, child = false) {
      if (this.navItem.navItems) {
        const vm = this
        if (isHovered) {
          clearTimeout(this.dropdownTimeout)
          if (this.$refs.menuLink) { this.$refs.menuLink.visible = true }
        } else if (!child) {
          this.dropdownTimeout = setTimeout(function () {
            if (vm.$refs.menuLink) { vm.$refs.menuLink.visible = false }
          }, 250)
        }
      }
    },
    isActiveDropdown (navItem) {
      const vm = this
      return navItem.navItems
        ? (navItem.navItems.some(item => item.to === vm.$route.path)) ||
        navItem.to === vm.$route.path
        : false
    }
  }
}
